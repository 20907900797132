<template>
  <div>
    <today-info
      :info-data="infoData"
      :today-data="getAreaDailyTotal"
      :total-data="getAreaTotal"
    ></today-info>

    <hr class="my-5" />

    <template v-if="selected_devices.length > 0">
      <device-card :items="selected_devices"></device-card>
      <hr class="my-5"/>
    </template>

    <area-product-card :areaName="stationFieldsInfo.name">
    </area-product-card>

    <hr class="my-5" />

    <area-inverters-product-card :areaName="stationFieldsInfo.name">
    </area-inverters-product-card>

    <hr class="my-5" />

    <area-inverters-card :areaName="stationFieldsInfo.name" :invertersData="invertersInfo">
    </area-inverters-card>

    <hr class="my-5" />

    <station-field-inverter-alarm-list-card :station-field-name="stationFieldsInfo.name">
    </station-field-inverter-alarm-list-card>
    
  </div>
</template>

<script>
import {
  GET_AREA,
  GET_AREA_DAILY_TOTAL,
  GET_AREA_TOTAL,
  RESET_AREA_CARD_DATAS
} from "@/core/services/store/areas.module";
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TodayInfo from '../../custom/info/TodayInfo.vue';
import DeviceCard from '../../custom/device/DeviceCard.vue';

import moment from "moment/moment";
import AreaInvertersProductCard from './components/AreaInvertersProduct/AreaInvertersProductCard.vue';
import AreaProductCard from './components/AreaProduct/AreaProductCard.vue';
import AreaInvertersCard from './components/AreaInverters/AreaInvertersCard.vue';
import StationFieldInverterAlarmListCard from './components/StationFieldInverterAlarmList/StationFieldInverterAlarmListCard.vue';
moment().locale();

export default {
  name: "areas.show",
  components: {
    TodayInfo,
    DeviceCard,
    AreaInvertersProductCard,
    AreaProductCard,
    AreaInvertersCard,
    StationFieldInverterAlarmListCard,
  },
  data() {
    return {
      invertersInfo:[],
      stationFieldsInfo:[],
      //Tanımlı Cihazlar
      selected_devices:[],
      infoData: {
        header: ["Adı", "Santrali", "Alan", "Kurulu Güç"],
        data: [],
        ready: false,
      },
    };
  },
  computed: {
    ...mapGetters([
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
      "getAreaDailyTotal",
      "getAreaTotal"
    ]),
    getImage() {
      return process.env.BASE_URL + "media/images/ges1.jpg";
    },
  },
  mounted() {},
  methods: {
  },
  created() {
    this.$store.dispatch(RESET_AREA_CARD_DATAS);
    this.tableLoading = true;
    this.$store
      .dispatch(GET_AREA, this.$route.params.id)
      .then((res) => {
        
        this.invertersInfo = res.invertersInfo;
        this.stationFieldsInfo = res.stationFieldsInfo;

        //Detay Verilerini Getirme
        this.infoData.data = [
          this.stationFieldsInfo.name,
          this.stationFieldsInfo.power_station_name,
          this.stationFieldsInfo.area,
          this.stationFieldsInfo.installed_power,
        ];
        this.infoData.ready = true;

        //Tanımlı Cihazları Getirme
        if(this.stationFieldsInfo.selected_devices){
        this.selected_devices = this.stationFieldsInfo.selected_devices.split(';');
        }
        else{
        this.selected_devices = [];   
        }

        this.tableLoading = false;
      })
      .then(() => {
        this.$store.dispatch(SET_BREADCRUMB, [
          { title: "Sahalar", route: "/areas", icon: "flaticon2-layers-1" },
          { title: this.stationFieldsInfo.name },
        ]);
        this.$store.dispatch(GET_AREA_DAILY_TOTAL, {
          id: this.stationFieldsInfo.id,
          begin_date: moment().format('DD.MM.YYYY'),
        });
        this.$store.dispatch(GET_AREA_TOTAL, { id: this.stationFieldsInfo.id });
      })
  },
};
</script>
