<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card p-4">
        <table
          id="areaProductTable"
          class="table table-sm table-striped table-bordered table-hover w-100"
        >
          <thead>
            <tr>
              <th class="text-center">#</th>
              <th class="text-center">Tarih</th>
              <th class="text-center">Üretim</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in areaData" :key="item.id">
              <td>{{ index + 1 }}</td>
              <td class="text-nowrap text-center" :data-order="item.timestamp">
                {{ item.dateConvert }}
              </td>
              <td
                class="text-nowrap text-right"
                :data-order="item.total.toFixed(2)"
              >
                {{ item.totalConvert }}
              </td>
            </tr>
          </tbody>
          <!-- <tfoot>
            <tr>
              <td colspan="3" class="text-end">Toplam: {{ totalConvert }}</td>
            </tr>
          </tfoot> -->
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { wattConvertor } from "@/assets/js/functions/wattConvertor";
import "@/assets/js/functions/datatables.settings";
import $ from "jquery";

export default {
  name: "area_product_table",
  data() {
    return {
      table: $("#areaProductTable").DataTable(),
      total: 0,
    };
  },
  props: ["areaData", "fileName"],
  computed: {
    totalConvert() {
      return wattConvertor(this.total, "h");
    },
  },
  methods: {
    updateTable() {
      this.table.destroy();
      this.$nextTick(() => {
        this.table = $("#areaProductTable").DataTable({
          columnDefs: [{ type: "num", targets: [0, 1, 2] }],
          buttons: [
            {
              extend: "colvis",
              className: "btn-sm btn-primary mr-1",
              text: "Göster&Gizle",
            },
            {
              extend: "excel",
              className: "btn-sm btn-danger",
              title: this.fileName,
              filename: this.fileName,
            },
          ],
        });
      });
    },
  },
  watch: {
    areaData(data) {
      this.total = 0;
      data.forEach((item) => {
        this.total += item.total;
      });
      this.updateTable();
    },
  },
};
</script>
