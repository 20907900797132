<template>
  <div class="main-card card mb-4">
    <div class="card-header">
      <h2>
        {{ areaName }} Üretimleri
        <a @click="showProductInfo = !showProductInfo">
          <i
            v-if="showProductInfo"
            class="fas fa-chevron-down card-toggle-button"
          ></i>
          <i v-else class="fas fa-chevron-right card-toggle-button"></i>
        </a>
      </h2>
    </div>
    <div class="card-body py-4" v-show="showProductInfo">
      <div class="row">
        <div class="col-12">
          <div class="report-header">
            <div class="report-date-select-group">
              <h4 class="mr-2">Rapor Tarih Tipi</h4>
              <div class="switch-button-group">
                <button
                  @click="setReportDateType(0)"
                  class="first-switch-button"
                  :class="reportDateType == 0 ? 'btn-primary' : 'btn-secondary'"
                  :disabled="reportDateType == 0"
                >
                  Aylık
                </button>
                <button
                  @click="setReportDateType(1)"
                  class="second-switch-button"
                  :class="reportDateType == 1 ? 'btn-primary' : 'btn-secondary'"
                  :disabled="reportDateType == 1"
                >
                  Yıllık
                </button>
              </div>
            </div>
            <div class="report-type-select-group">
              <h4 class="mr-2">Rapor Tipi</h4>
              <div class="switch-button-group">
                <button
                  @click="setReportType(0)"
                  class="first-switch-button"
                  :class="reportType == 0 ? 'btn-primary' : 'btn-secondary'"
                  :disabled="reportType == 0"
                >
                  Tablo
                </button>
                <button
                  @click="setReportType(1)"
                  class="second-switch-button"
                  :class="reportType == 1 ? 'btn-primary' : 'btn-secondary'"
                  :disabled="reportType == 1"
                >
                  Grafik
                </button>
              </div>
            </div>
          </div>
          <div class="report-title-group my-4">
            <div class="report-title">
              <h2 class="mr-1" v-if="reportDateType == 0">Aylık</h2>
              <h2 class="mr-1" v-else>Yıllık</h2>
              <h2 v-if="reportType == 0">Tablo</h2>
              <h2 v-else>Grafik</h2>
            </div>
            <div class="report-date">
              <date-picker
                :editable="false"
                v-model="selectedDate"
                valueType="format"
                lang="tr"
                :format="dateFormat"
                @change="dateChange()"
                :type="dateType"
                :default-value="defaultDate"
                :disabled-date="disabledDate"
              >
              </date-picker>
            </div>
          </div>
          <div class="report-body">
            <div class="report-loading card py-3 mx-auto" v-if="reportLoading">
              <h3>
                {{ reportDateType == 0 ? "Aylık" : "Yıllık " }}
                {{ reportType == 0 ? "Tablonuz" : "Grafiğiniz" }}
                Oluşturuluyor
                <br />
                Lütfen Bekleyiniz
                <i class="fas fa-spinner fa-pulse text-white"></i>
              </h3>
            </div>
            <area-product-table
              v-show="!reportLoading && reportType == 0"
              :area-data="areaData"
              :file-name="areaName + ' ' + selectedDate + ' Üretim Raporu'"
            ></area-product-table>
            <area-product-graph
              v-show="!reportLoading && reportType == 1"
              :area-data="areaData"
              :date-type="reportDateType"
            ></area-product-graph>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/sass/switch_button_custom.scss";

//Deneme
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/tr";
import "vue2-datepicker/index.css";

import { wattConvertor } from "@/assets/js/functions/wattConvertor";
import moment from "moment/dist/moment";
moment().locale();

import {
  GET_AREA_INVERTER_DAILY_TOTAL,
  GET_AREA_INVERTER_MONTHLY_TOTAL,
} from "@/core/services/store/areas.module";
import AreaProductTable from "./AreaProductTable.vue";
import AreaProductGraph from "./AreaProductGraph.vue";

export default {
  components: { DatePicker, AreaProductTable, AreaProductGraph },
  name: "area_product_card",
  props: {
    areaName: {
      required: true,
    },
  },
  data() {
    return {
      showProductInfo: true, // Kart Göster&Gizle
      reportDateType: 0, //0 = Günlük / 1 = Aylık
      reportType: process.env.VUE_APP_DEFAULT_SHOW_REPORT_TYPE, //0 = Tablo / 1 = Grafik
      selectedDate: moment().format("YYYY/MM"),
      defaultDate: moment().format("YYYY/MM"),
      dateType: "month",
      dateFormat: "YYYY/MM",
      reportLoading: true,
      areaData: null,
    };
  },
  methods: {
    setReportDateType(type) {
      this.reportDateType = type;
      if (type == 0) {
        this.dateFormat = "YYYY/MM";
        this.dateType = "month";
        this.selectedDate = moment().format(this.dateFormat);
        this.getAreaDaily();
      } else {
        this.dateFormat = "YYYY";
        this.dateType = "year";
        this.selectedDate = moment().format(this.dateFormat);
        this.getAreaMonthly();
      }
    },
    setReportType(type) {
      this.reportType = type;
      if (type == 0) {
        if (this.reportDateType == 0) {
          this.getAreaDaily();
        } else {
          this.getAreaMonthly();
        }
      }
    },
    dateChange() {
      if (this.reportDateType == 0) {
        this.getAreaDaily();
      } else {
        this.getAreaMonthly();
      }
    },
    disabledDate(date) {
      return date > new Date();
    },
    getAreaDaily() {
      this.reportLoading = true;
      this.$store
        .dispatch(GET_AREA_INVERTER_DAILY_TOTAL, {
          id: this.$route.params.id,
          begin_date: this.selectedDate.replace("/", "-"),
        })
        .then((res) => {
          this.areaData = res;
          this.reportLoading = false;
        });
    },
    getAreaMonthly() {
      this.reportLoading = true;
      this.$store
        .dispatch(GET_AREA_INVERTER_MONTHLY_TOTAL, {
          id: this.$route.params.id,
          begin_date: this.selectedDate + "-01",
        })
        .then((res) => {
          this.areaData = res;
          this.reportLoading = false;
        });
    },
  },
  created() {
    this.getAreaDaily();
  },
};
</script>
